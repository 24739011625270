<template>
	<v-container row wrap>
		<v-flex xs12>
			<h3 v-if="item" class="ml-5">
				<v-btn @click="$router.back()" icon><v-icon>mdi-arrow-left</v-icon></v-btn>
				{{ item.full_name }}
				--
				{{ "images" | trans }}
			</h3>
		</v-flex>
		<v-flex xs12 md4 lg3 sm4 v-for="image in images" :key="image.id">
			<v-card tile class="pa-2 ma-5" height="280px">
				<v-img :src="image.url" aspect-ratio="1" height="230px" contain="">
					<template v-slot:placeholder>
						<v-row class="fill-height ma-0" align="center" justify="center">
							<v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
						</v-row>
					</template>
				</v-img>
				<v-card-actions>
					<v-chip label color="primary" outlined small v-if="item.image_id == image.id"
						>{{ "image" | trans }} {{ "default" | trans }}</v-chip
					>
					<v-spacer></v-spacer>
					<v-btn x-small v-if="item.image_id != image.id" @click="defaultImage(image.id)" color="primary">
						{{ "default" | trans }}
					</v-btn>
					<v-btn x-small color="red" dark @click="deleteImage(image.id)" v-if="item.image_id != image.id">
						{{ "crud.delete" | trans }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-flex>

		<v-flex xs12 md4 lg3 sm4>
			<v-card class="pa-2 ma-5" tile height="280px">
				<v-btn outlined left width="100%" height="100%" color="primary" @click="addImage">
					<v-icon left x-large>mdi-plus-circle-outline</v-icon>
					<span class="ma-3">{{ "crud.add" | trans }}</span>
				</v-btn>
			</v-card>
		</v-flex>
	</v-container>
</template>
<script>
import IMEX from "@/services/IMEX";
export default {
	mounted() {
		this.getData();
	},
	methods: {
		getData() {
			this.api
				.get(`${this.resource}/${this.id}?include=${this.relation},image`)
				.then((resp) => {
					this.item = resp.data;
					this.images = resp.data[this.relation];
					if (resp.data.image && !this.images.find((i) => i.id == resp.data.image_id)) {
						this.images.unshift(resp.data.image);
					}
					this.loading = true;
				})
				.catch(console.error);
		},
		addImage() {
			IMEX.import("image", "image/*")
				.then((resp) => {
					if (!["jpeg", "jpg", "png"].includes(resp.extension)) {
						this.api.handleError("No es una Imagen");
					}
					console.log(resp);
					this.api
						.post(`images/upload/${this.model}/${this.id}`, {
							image: resp.file,
						})
						.then((resp) => {
							console.log(resp);
							this.getData();
						})
						.catch(console.error);
				})
				.catch((err) => {
					console.error(err);
				});
		},
		deleteImage(id) {
			this.api
				.delete(`${this.relation}/${id}`)
				.then(() => {
					this.getData();
				})
				.catch((err) => {
					this.api.handleError(err);
				});
		},
		defaultImage(id) {
			this.api
				.put(`productos/${this.id}/images/${id}/default`)
				.then(() => {
					this.getData();
				})
				.catch((err) => {
					this.api.handleError(err);
				});
		},
	},
	data() {
		return {
			loading: false,
			images: [],
			item: null,
		};
	},
	props: {
		relation: {
			type: String,
			default: "images",
		},
		resource: {
			type: String,
			default: "productos",
		},
		id: {
			type: [Number, String],
			required: true,
		},
	},
	computed: {
		model() {
			if (this.resource == "productos") {
				return "producto";
			}
			return this.resource;
		},
	},
};
</script>
